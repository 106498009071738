<!-- <app-home-header></app-home-header> -->
<link rel="stylesheet" href="//cdnjs.cloudflare.com/ajax/libs/ol3/3.5.0/ol.min.css" type="text/css">
<ng-template [ngIf]="visible">
  <div class="bradcam_area bradcam_bg_1" [style.padding]=" details &&!details[0]?' 182px 0 102px':'20px'"
    style="text-align: -webkit-center;padding: 20px;">
    <div class="col-md-7" *ngIf="details &&  details.length >=0" style="  
      background-image: linear-gradient(rgb(255 255 255 / 50%), rgb(255 255 255 / 50%)), url(https://telefret.com/assets/img/logo.png);
    background-repeat: no-repeat;
    background-position: center;
  background-color: white;
  filter: drop-shadow(0 1px 4px rgba(0,0,0,0.2));
  margin: 25px;
  border-radius: 10px;
  text-align: start;
  border: 1px solid #cccccc;
  bottom: 12px;
  min-width: 280px;margin-left: auto!important;margin-right: auto!important; ">

      <div class="row">
        <div class="col-md-6">
          <p style="color: black!important">Client:
            {{details[0].Chargement?.client?details[0]?.Chargement?.client:""
            }} </p>
          <p style="color: black!important">Téléphone:
            {{details[0].Chargement?.telephoneclient?details[0]?.Chargement?.telephoneclient:"" }} </p>
          <p style="color: black!important">Adresse:
            {{details[0].Chargement?.adresseclient?details[0]?.Chargement?.adresseclient:"" }} </p>
        </div>
        <div class="col-md-6">
          <p style="color: black!important">Transorteur: Socopat</p>
          <p style="color: black!important">Téléphone: </p>
          <p style="color: black!important">Adresse: </p>
        </div>
      </div>
      <div class="row" style="display: flex;
  justify-content: center;">
        <h6>Détails</h6>
      </div>
      <div class="row">
        <div class="col-md-6">

          <p style="color: black!important"> Type de marchandise:
            {{details[0].Chargement?.marchandise?details[0]?.Chargement?.marchandise:"" }} </p>

          <p style="color: black!important">Adresse de chargement : {{ details[0]?.Chargement?.adresse_charg+","+thisatob(details[0]?.Chargement?.ville_charg) }}</p>
          <p style="color: black!important">Date de chargement: {{ details[0]?.Chargement?.date_charg }}</p>

        </div>
        <div class="col-md-6">
          <p style="color: black!important">Adresse de livraison : {{ details[0]?.Chargement?.adresse_liv+","+ thisatob(details[0]?.Chargement?.ville_liv) }}</p>
          <p style="color: black!important">Date de livraison: {{ details[0]?.Chargement?.date_liv }}</p>

        </div>
      </div>

    </div>
    <div class="row">


      <div class="col-xl-12"
        style="text-align: -webkit-center;margin-left: auto!important;margin-right: auto!important;">
        <div class="text-align-center">
          <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
            <div class="col-md-12">
              <h5 for="" style="color: white;">Saisissez le N° d'expédition</h5>
            </div>
          </div>
          <div class="input-group col-md-3" style="margin-left: auto!important;margin-right: auto!important;">
            <div class="col-md-12">
              <button type="button" (click)="onChange(value,true);onChange(value,false);" style="
            position:absolute; right: 10px
            " class="btn btn-success">
                <i class="fa fa-search my-float"></i>
              </button>
              <input [ngModel]="value" (ngModelChange)="onChangeValue($event)" type="search" id="form1"
                class="form-control" />

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<div class="wrapper ">

  <div id="map" class="{{!valueFind?'demo-area1':'demo-area'}} map"></div>

</div>

<div id="popup" class="ol-popup">
  <a href="#" id="popup-closer" class="ol-popup-closer"></a>
  <div id="popup-content"></div>
</div>